<template>
  <v-container fluid fill-height>
    <v-row justify="center">
      <v-col cols="12">
      <h1 v-text="$tc('admin.sections')" 
        class="text-center"></h1>
      </v-col>
      <v-col cols="4" md="3" :class="{
          'pa-1':true
        }"
          v-for="(item,index) in sections" :key="`subItem-${index}`">
          <v-card :class="'listcard elevation-0'" :to="item.link" class="card-border">
            <v-card-text>
            <v-row>
              <v-col cols="12" 
                class="text-center text-uppercase font-weight-black">
                <v-icon :size="$vuetify.breakpoint.smAndDown?40:60" v-text="item.icon"></v-icon>
              </v-col>
              <v-col cols="12" 
                :class="{
                  'text-truncate':true,
                  'font-weight-black':true, 
                  'text-center':true, 
                  'text-uppercase':true,
                  'caption':$vuetify.breakpoint.smAndDown
                }">
                {{ item.title }}
              </v-col>
            </v-row>  
            </v-card-text>
          </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      sections: []
    }
  },
  mounted() {
    if(this.$store.getters.isAdmin) {
      this.sections = [
        { title: this.$tc('admin.operator'), link: "/operators", icon: 'fas fa-user-cog' },
        { title: this.$t('admin.ticket'), link: "/tickets", icon: 'fas fa-ticket-alt' },
      ]
    } else if(this.$store.getters.isOperator) {
      this.sections = [
        { title: this.$tc('admin.ticket'), link: "/free-tickets", icon: 'fas fa-ticket-alt' },
        { title: this.$t('admin.my_tickets'), link: "/my-tickets", icon: 'fas fa-user-tag' },
      ]
    }
  }
}
</script>

<style scoped>
  .card-border{
    border: 1px #a6a6a6 solid;
  }
  .card-border:hover{
    background-color: #fafafa;
  }
</style>
