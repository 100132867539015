var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-center",domProps:{"textContent":_vm._s(_vm.$tc('admin.sections'))}})]),_vm._l((_vm.sections),function(item,index){return _c('v-col',{key:("subItem-" + index),class:{
        'pa-1':true
      },attrs:{"cols":"4","md":"3"}},[_c('v-card',{staticClass:"card-border",class:'listcard elevation-0',attrs:{"to":item.link}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-center text-uppercase font-weight-black",attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.smAndDown?40:60},domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-col',{class:{
                'text-truncate':true,
                'font-weight-black':true, 
                'text-center':true, 
                'text-uppercase':true,
                'caption':_vm.$vuetify.breakpoint.smAndDown
              },attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }